import React from "react"
import { Waypoint } from 'react-waypoint';
import { Link } from 'gatsby'

import {handleWaypointIn} from "../utilities/waypoint"
import Layout from "../components/layout/Layout"
import ContactSection from "../components/contact/ContactSection"
import SEO from "../components/seo"

const EcommerceWebDevelopment = () => {
  
    return (
        <div className="ecommerce">
            <Layout>
                <SEO 
                    title="Ecommerce Web Development"
                    description="Ecommerce Web Development | Woocommerce Ecommerce Web Development | Shopify Ecommerce Web Development" 
                />
                <section className="page-header">
                    <Waypoint onEnter={() => handleWaypointIn('tech-text', 'slide-in-elliptic-right-fwd')}>         
                        <div id="tech-text">                    
                            <h1>Ecommerce Web Development</h1> 
                        </div>    
                    </Waypoint>                             
                </section> 

                <section className="content">
                    <div className="gray-bg">
                        <Waypoint onEnter={() => handleWaypointIn('sell-text')}>         
                            <div id="sell-text">                        
                                <h2>Do you want to sell online?</h2>

                                <p>Do you have a product or products that you want to sell online? Do you need help setting up and creating a shop online? Are you looking for a developer to build your ecommerce store for you? Then you have found the right developer!</p>

                                <p>I've worked on many ecommerce sites in my time mainly Woocommerce and a few Magento sites. I would recommend Woocommerce or for shops that need less customisation then I would recommend Shopify.</p>

                                <p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
                            </div>    
                        </Waypoint>  
                    </div>

                    <div className="white-bg">
                        <Waypoint onEnter={() => handleWaypointIn('shopify-text')}>         
                            <div id="shopify-text">    
                                <h2>Shopify</h2>

                                <p>Shopify excels for small businesses or shops with more simple requirements who want to get online as soon as possible in one language.</p>

                                <p>Shopify is a hosted SAAS (Software As A Service) ecommerce shopping platform that allows shops to be setup and launched quickly. Being a SAAS you don't have to worry about hosting as they take care of it for you. They have a number of templates to choose from and some minor customisation can be made. They even have their own Shopify Payment solution. However, the amount of functionality that comes out fo the box with Shopify is a lot less than Woocommerce.</p>

                                <p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
                            </div>    
                        </Waypoint>  
                    </div>
                    
                    <div className="gray-bg">
                        <Waypoint onEnter={() => handleWaypointIn('shopify-pros-text')}>         
                            <div id="shopify-pros-text">                       
                                <h4>Shopify Pros</h4>

                                <ul>
                                    <li>Host solution means you don't need to sort your own hosting out or worry about maintenance. It's fast and secure web hosting deployed across a large content delivery network (CDN), wich means it's fast wherever your customers are in the world. This means it's very scalable.</li>
                                    <li>There are a number of starter themes to chose from and so getting your site live can be acheived quite quickly. You can also extend these themes or create your own.</li>
                                    <li>Shopify is certified Level 1 PCI DSS compliant, which means your data - products, orders and customers are safe. </li>
                                    <li>Some of the worlds biggest brands, such as Hasbro, Heinz and Kylie Cosmetics, trust Shopify as their ecommerce platform provider.</li>
                                    <li>Updates are rolled out automatically and tested by the Shopify team.</li>
                                </ul>


                            </div>    
                        </Waypoint>                                  
                    </div>

                    <div className="white-bg">
                        <Waypoint onEnter={() => handleWaypointIn('shopify-cons-text')}>         
                            <div id="shopify-cons-text">                     
                                <h4>Shopify Cons</h4>

                                <ul>
                                    <li>Shopify provides core functionality for all users. But if you require extra functionality it's quite limited. For more customisations then Woocommerce (Wordpress) is much better.</li>
                                    <li>If you only want an online store then Shopify is great but if you want extra pages then the Shopify CMS is very limited and nowhere near as good as Woocommerce.</li>
                                    <li>Whilst you can have more than one language the process is not an easy or quick one. There are apps to do this but they are either not cheap or easy to use. Therefore, Woocommerce is much better suited at multi-lingual sites.</li>
                                    <li>You have to pay a monthly fee for one of their levels of service. The more you may the more functionality you get.</li>
                                    <li>You can use another payment provider (such as Stripe) but you have yo pay Shopify a fee on top of each transactions</li>
                                    <li>Whilst Shopify hosting is great you don't have the option to host yourself if you wanted to - you have to use their servers and cannot run Shopify yourself.</li>
                                    <li>The URL for pages/bloges etc aren't as great and are in a subdirectory such as pages/ or blog/.</li>
                                </ul>
                            </div>    
                        </Waypoint>                                    
                    </div>
                    
                    <div className="gray-bg">
                        <Waypoint onEnter={() => handleWaypointIn('woocommerce-text')}>         
                            <div id="woocommerce-text">                     
                                <h2>Woocommerce</h2>

                                <p>Woocommerce is a great option for stores who want more customisation- be it a theme or functionality or require multi-lingual site.</p>

                                <p>Woocommerce is an ecommerce plugin for Wordpress. So all the benefits and ease of use of Wordpress also apply for Woocommerce. There are thousands of plugins and themes you can use. It's also much easier to customise and extend than Shopify. However, you need to host Woocommerce yourself and unless you're using a pre-made theme it takes longer to get online compared to Shopify. Woocommerce plus WPML plugin means having a multi-lingual site is much easier.</p>

                                <p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
                            </div>    
                        </Waypoint>  
                    </div>

                    <div className="white-bg">
                        <Waypoint onEnter={() => handleWaypointIn('woocommerce-pros-text')}>         
                            <div id="woocommerce-pros-text">                     
                                <h4>Woocommerce Pros</h4>

                                <ul>
                                    <li>3rd party integrations easier. If you have a third-party system e.g CRMs to ERPs, from Salesforce to a custom solution, via APIs, then I can connect them to your website through custom web development</li>
                                    <li>With WPML multilingual sites are much easier to create and use.</li>
                                    <li>Easy to extend through thousands of plugins or writing custom code.</li>
                                    <li>Easier to create your own theme</li>
                                    <li>You get to host with whoever you want and can control the hosting costs yourself.</li>
                                    <li>Use any payment provider you like - such as Paypal, Stripe, Alipay, etc</li>
                                    <li>Woocommerce and Wordpress is free to use.</li>
                                    <li>You can create any type of pages you like and lay them out however you like. There are various types of page builders (including the default Gutenberg editor that comes with Wordpress) as well.</li>
                                </ul>  
                            </div>    
                        </Waypoint>                          
                    </div>
                    
                    

                    <div className="gray-bg">
                        <Waypoint onEnter={() => handleWaypointIn('woocommerce-cons-text')}>         
                            <div id="woocommerce-cons-text">                      
                                <h4>Woocommerce Cons</h4>

                                <ul>
                                    <li>You have to host yourself. You can chose hosts who do managed hosting for you and even install wordpress so you have to just add your theme and plugins. But for ease of use for non-technical people it's not as easy as Shopify.</li>       
                                    <li>You either need to pay for managed hosting of have someone who can help perform maintenance for you.</li>
                                    <li>Whilst it's free, and a lot of plugins are free, you still have to pay for hosting. If you want premium plugins you have to pay (either yearly or one-off lifetime payment) and customisation costs money as well (different developers have different costs but more importantly different standards). This means is can cost as much or more than Shopify.</li>  
                                    <li>If you use the wrong plugin, or don't keep your Wordpress and plugins up to date they can be a security risk. I recommend you always use (at least the free version) Wordfence security plugin and always update Wordpress and your plugins.</li>
                                    <li>You have to not only update Wordpress and your plugins yourself but also test they work first as you sometimes conflicts arise. Or pay someone like myself to do it.</li>               
                                </ul>
                            </div>    
                        </Waypoint>                           
                    </div>
                    
                </section>

                <ContactSection/>                            
            </Layout> 
        </div>
    )
}

export default EcommerceWebDevelopment